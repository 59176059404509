.widget-2-col-img-txt-left,
.widget-2-col-img-txt-right {
    margin-bottom: 180px;

    @include breakpoint (medium only) {
        font-size: 140px;
    }

    img {
        width: 100%;
    }

    .text {
        background-color: unset;
        position: relative;
        z-index: 1;

        &:after {
            content: '';
            background-color: $primary-color;
            height: 78%;
            position: absolute;
            z-index: -1;

            @include breakpoint (medium) {
                width: 100%;
                bottom: -8%;
            }

            @include breakpoint (small only) {
                width: 120%;
                height: 90%;
                left: -10%;
                bottom: -4%;
            }
        }

        img {
            @include breakpoint (large) {
                margin-bottom: 35px;
            }

            @include breakpoint (medium only) {
                margin-bottom: 25px;
            }

            @include breakpoint (small only) {
                margin-bottom: 20px;
            }
        }
    }

    p, h2 {
        @include breakpoint (small only) {
            text-align: center;
        }
    }

    h2 {
        @include breakpoint (medium only) {
            font-size: rem-calc(28);
        }
    }
}

.widget-2-col-img-txt-right {
    .text {
        &:after {
            @include breakpoint (medium) {
                right: 10%;
            }
        }
    }

    p, h2 {
        @include breakpoint (medium) {
            margin-right: 28%;
        }
    }
}

.widget-2-col-img-txt-left {
    .text {
        &:after {
            @include breakpoint (medium) {
                left: 10%;
            }
        }
    }

    p, h2 {
        @include breakpoint (medium) {
            margin-left: 28%;
        }
    }
}
