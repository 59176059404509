.widget-circles {
    margin-right: -10px;
    margin-bottom: 60px;

    h2 {
        padding-left: 20px;
        margin-bottom: 20px;
    }

    @include breakpoint (medium) {
        display: none;
    }

    .items {
        padding-left: 20px;

        a {
            // margin-right: 20px;
            display: inline-block;
            width: 110px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            color: $black;
        }

        img {
            border-radius: 55px;
            overflow: hidden;
            margin-bottom: 10px;
        }
    }

    .owl-stage-outer {
        overflow: hidden;
    }

    .owl-stage {
        display: flex;
        // margin: 0 20px;
    }

    .owl-item {
        display: block;
        min-width: 130px;
        width: 130px;

        a {
            padding-right: 20px;
            width: 130px;
            display: block;

            img {
                width: 110px;
                height: 110px;
                display: block;
            }
        }

        &:first-child {
            // width: 150px;
            // margin-left: 20px;

            a {
                // padding-left: 20px;
                // margin-left: 20px;
            }
        }

        &:last-child {
            // width: 110px;
            
            a {
                // margin-right: 20px;
            }
        }
    }

    .owl-nav, 
    .owl-dots {
        display: none;
    }
}
