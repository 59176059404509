.widget-2-col-txt-video {
    @include xy-grid;
    position: relative;

    @include breakpoint (small only) {
        margin-top: 110px;
        margin-bottom: 110px;
    }

    @include breakpoint (medium only) {
        margin-top: 195px;
        margin-bottom: 55px;
    }

    @include breakpoint (large) {
        margin-bottom: 170px;
    }

    h2 {
        @include breakpoint (small only) {
            font-size: rem-calc(28);
        }
    }

    .text {
        background-color: $primary-color;

        @include breakpoint (small only) {
            padding-top: 220px;
            padding-bottom: 20px;
            @include xy-cell(10 of 10, $gutters: 0);
        }

        @include breakpoint (medium only) {
            @include xy-cell(12, $gutters: 0);
            padding-top: 200px;
            padding-bottom: 40px;
            text-align: center;
        }

        @include breakpoint (large) {
            @include xy-cell(10 of 12, $gutters: 0);
            padding: 67px 46px;
            min-height: 300px;
        }

        @include breakpoint (xlarge) {
            min-height: 400px;
        }

        > div {
            @include breakpoint (small only) {
                @include xy-cell(8 of 10, $gutters: 0);
                @include xy-cell-offset(1 of 10, $gutters: 0, $gutter-type: padding);
            }

            @include breakpoint (medium only) {
                @include xy-cell(8, $gutters: 0);
                @include xy-cell-offset(2, $gutters: 0, $gutter-type: padding);
            }

            @include breakpoint (large) {
                @include xy-cell(5 of 10, $gutters: 0);
            }
        }
    }

    .video {
        position: absolute;

        @include breakpoint (small only) {
            position: relative;
            order: -2;
            margin: 0 auto -150px auto;
        }

        @include breakpoint (medium only) {
            // top: -40%;
            position: relative;
            order: -2;
            margin: 0 auto -150px auto;
        }

        // @include breakpoint (medium down) {
        //     width: 80%;
        //     left: 50%;
        //     transform: translateX(-50%);
        // }

        @include breakpoint (large) {
            width: 50%;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}