.widget-2-col-img-txt-img {
    @include xy-grid;

    @include breakpoint (small only) {
        margin-bottom: 120px;
    }

    @include breakpoint (medium only) {
        margin-bottom: 100px;
    }

    @include breakpoint (large) {
        margin-bottom: 160px;
    }

    img {
        width: 100%;
    }

    .text {
        background-color: $primary-color;

        @include breakpoint (small only) {
            margin-left: -10px;
            margin-right: -10px;
            padding: 20px;
            text-align: center;
        }

        @include breakpoint (medium) {
            position: absolute;
            z-index: -1;
        }

        @include breakpoint (medium only) {
            left: 40px;
            right: -40px;
            padding: 20px 40px;
        }

        @include breakpoint (large) {
            left: 80px;
            right: -80px;
            padding: 20px 60px;
        }
    }

    .left {
        position: relative;
        z-index: 1;

        @include breakpoint (small) {
            padding: 0 10px;
            @include xy-cell(12, $gutters: 0);
        }

        @include breakpoint (medium) {
            @include xy-cell(6, $gutters: 0);
        }

        &:after {
            content: '';
            background-color: $primary-color;
            position: absolute;
            width: 100%;
            z-index: -2;

            @include breakpoint (small only) {
                left: 0;
                right: 0;
                top: 20%;
                height: 60%;
            }

            @include breakpoint (medium) {
                top: 30%;
                height: 50%;
            }

            @include breakpoint (medium only) {
                left: 40px;
                right: 40px;
            }

            @include breakpoint (large) {
                left: 80px;
                right: 80px;
            }
        }
    }

    .right {
        @include breakpoint (small only) {
            display: none;
        }

        @include breakpoint (medium) {
            @include xy-cell(4, $gutters: 0);
            @include xy-cell-offset(2, $gutters: 0, $gutter-type: padding);
        }

    }
}