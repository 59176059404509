.widget-3-col-txt-img-img {
    @include xy-grid;
    position: relative;

    @include breakpoint (small) {
        margin-bottom: 80px;
    }

    @include breakpoint (medium) {
        margin-bottom: 120px;
    }

    @include breakpoint (large) {
        margin-bottom: 180px;
    }

    .image-left,
    .image-right {
        @include xy-cell($gutters: 0);

        img {
            width: 100%;
        }
    }

    .image-left {
        @include breakpoint (medium) {
            @include xy-cell(7, $gutters: 0);
        }

        @include breakpoint (medium down) {
            display: none;
        }
    }

    .image-right {
        @include breakpoint (medium only) {
            @include xy-cell(5, $gutters: 0);
        }

        @include breakpoint (large) {
            @include xy-cell(3, $gutters: 0);
            position: absolute;
            bottom: -15%;
            right: 0;
        }

        @include breakpoint (small only) {
            padding: 0 20px;
            position: relative;
            z-index: 1;
            order: -1;
        }

        &:before {
            @include breakpoint (small only) {
                content: '';
                position: absolute;
                background-color: $primary-color;
                top: 15%;
                bottom: 0;
                right: 0;
                left: 0;
                z-index: -1;
            }
        }
    }

    .text {
        position: relative;
        z-index: -1;
        @include xy-cell($gutters: 0);
        @include flex;
        @include flex-align(center, middle);

        @include breakpoint (medium) {
            @include xy-cell(7, $gutters: 0);
            transform: translateY(15%);
        }

        @include breakpoint (large) {
            @include xy-cell(4, $gutters: 0);
        }

        &:after {
            content: '';
            position: absolute;
            background-color: $primary-color;
            top: 0;
            bottom: 0;
            left: 100%;
            @include xy-cell(6 of 7, $gutters: 0);

            @include breakpoint (small only) {
                display: none;
            }
        }

        .inner {
            padding: 0 40px;

            @include breakpoint (small only) {
                text-align: center;
            }

            @include breakpoint (medium only) {
                padding: 20px 40px;
            }

            @include breakpoint (small only) {
                padding: 20px;
            }
        }

        h2 {
            line-height: 1.1;
            margin-bottom: 20px;
        }
    }

    .button.editorial {
        @include breakpoint (small only) {
            position: absolute;
            bottom: -19px;
            left: 50%;
            margin-left: -19px;
        }
    }
}
