.widget-3-col-img-shopbylook {
    position: relative;
    z-index: 1;

    @include breakpoint (medium down) {
        margin-bottom: 70px;
    }

    @include breakpoint (large) {
        margin-bottom: 150px;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: $primary-color;
        height: 60%;
        z-index: -1;

        @include breakpoint(medium down) {
            margin-left: -20px;
            margin-right: -20px;
        }

        @include breakpoint (small only) {
            right: 50px;
            left: 50px;
            bottom: auto;
            // top: 180px;
            top: 285px;
            height: 50%;
        }
    }

    .big-text {
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        top: 10%;
        text-align: center;
        margin-bottom: -200px;

        @include breakpoint(small only) {
            top: 5%;
        }
    }

    .slides {
        @include xy-grid;
        overflow: hidden;

        &.owl-loaded {
            display: block;
        }

        > .slide {
            @include xy-cell(4, $gutters: 0);

            &:nth-of-type(1n+4) {
                display: none;
            }
        }

        .slide {
            text-align: center;
            padding-bottom: 40px;

            @include breakpoint (small only) {
                padding-bottom: 0;
                position: relative;
            }

            h6 {
                font-size: 16px;
                line-height: 1.1;
                margin-bottom: 10px;
            }

            p {
                margin-bottom: 20px;

                @include breakpoint(small only) {
                    margin-bottom: 10px;
                }
            }

            img {
                @include breakpoint (large) {
                    height: 650px;
                    display: inline-block;
                }
            }
        }
    }

    img {
        margin-bottom: 20px;
    }

    .editorial {
        @include breakpoint (small only) {
            display: none;
        }
    }

    .owl-stage {
        @include xy-grid;
    }

    .owl-item {
        @include xy-cell(4, $gutters: 0);
    }

    .owl-prev,
    .owl-next {
        position: absolute;
        width: 38px;
        height: 38px;
        top: 50%;
        margin-top: -19px;
        font-size: rem-calc(36);
        color: $black;
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint (medium) {
            background-color: $secondary-color;
            color: $white;
            font-size: rem-calc(28);
        }

        // @include breakpoint(medium down) {
        //     display: none;
        // }

        span {
            display: inline-block;
            height: 18px;
            line-height: 14px;
        }
    }

    .owl-prev {
        left: -19px;

        @include breakpoint (medium down) {
            left: -10px;
        }
    }

    .owl-next {
        right: -19px;

        @include breakpoint (medium down) {
            right: -10px;
        }
    }

    .owl-dots {
        bottom: -25px;

        @include breakpoint (small only) {
            display: none;
            bottom: -40px;
        }
    }
}
