.widget-customer-care {
    position: relative;
    min-width: 100%;
    @include breakpoint (small only) {
        position: relative;
        margin-bottom: 65px;
    }

    @include breakpoint (medium) {
        @include xy-grid;
        @include xy-gutters($negative: true);
        background-color: $primary-color;

        &::after {
            content: '';
            height: 25px;
            width: 100vw;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: white;
        }
    }

    @include breakpoint (large) {
        margin-bottom: 60px;
    }

    .grid-container {
        z-index: 1;
        width: 100%;
        padding-bottom: 25px;
        overflow: hidden;
        // padding-bottom: 24px;
        // position: relative;

        // &::after {
        //     content: '';
        //     height: 24px;
        //     width: 100vw;
        //     position: absolute;
        //     bottom: 0;
        //     left: 50%;
        //     transform: translateX(-50%);
        // }
    }

    .box {
        background-color: $primary-color;
        text-align: center;
        @include flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;

        &:hover {
            .button.editorial {
                visibility: visible;
            }
        }

        .button.editorial {
            visibility: hidden;
            content: "";
            display: block;
            width: 38px;
            height: 38px;
            position: absolute;
            bottom: -19px;
            left: 50%;
            margin-left: -19px;
            border: 0;
            background-color: $secondary-color;
            border-radius: 19px;

            &:before {
                border-style: solid;
                border-width: 2px 2px 0 0;
                content: '';
                display: block;
                height: 9px;
                width: 9px;
                position: absolute;
                vertical-align: top;
                margin-left: -2px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%) rotate(45deg);
            }
            &:after {
                content: '';
                width: 46px;
                height: 46px;
                border: 2px solid $secondary-color;
                border-radius: 23px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }
    }

    a.box,
    .box > a {
        padding: 20px 20px;
    }

    // .owl-stage-outer {
    //     max-width: 100%;
    //     overflow: hidden;
    //     padding-bottom: 25px;
    //     width: 100%;
    // }

    .owl-stage {
        // background-color: $primary-color;
        display: flex;
        flex-direction: row;
        justify-content: center;
        // min-width: 100%;

        @include breakpoint (small only) {
            background: none;
        }

        // @include xy-grid;

        .owl-item {
            @include xy-cell(10, $gutters: 0);
            @include flex;
            // width: 165px !important;
            min-width: 165px;
            max-width: 165px;

            @include breakpoint (small only) {
                &.active.center {
                    .box {
                        .button.editorial {
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }

    .owl-dots {
        bottom: -25px;
    }

    .owl-nav {
        display: none;
    }

    h5 {
        line-height: 1.2;
        font-size: rem-calc(20);
        margin-bottom: 10px;

        @include breakpoint(medium down) {
            line-height: 1.1;
        }
    }

    img,
    svg {
        width: 100%;
        height: 32px;
        margin: 20px auto;
    }

    img {
        object-fit: contain;
    }

    p {
        font-size: rem-calc(14);
        margin-bottom: 20px;
        max-width: 100%;
    }
}
