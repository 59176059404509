.widget-1-col-txt-hero {
    background-color: $primary-color;
    position: relative;

    @include breakpoint (small) {
        padding: 60px 0;
    }

    @include breakpoint (large) {
        padding: 120px 0;
    }

    .text {
        text-align: center;
    }

    h2 {
        text-transform: uppercase;

        @include breakpoint (small) {
            font-size: rem-calc(20);
            line-height: rem-calc(24);
            margin-bottom: 10px;
        }

        @include breakpoint (large) {
            font-size: rem-calc(24);
            margin-bottom: 20px;
        }
    }

    h1 {
        font-weight: 300;

        @include breakpoint (small) {
            font-size: rem-calc(36);
            line-height: rem-calc(48);
            margin-bottom: 20px;
        }

        @include breakpoint (medium) {
            margin-bottom: 40px;
        }

        @include breakpoint (large) {
            font-size: rem-calc(60);
            line-height: rem-calc(66);
            margin-bottom: 40px;
        }
    }

    a.button.editorial {
        @include breakpoint (small only) {
            position: absolute;
            bottom: -19px;
            left: 50%;
            margin-left: -19px;
        }
    }
}
