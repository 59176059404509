.widget-2-col-txt-img {
    position: relative;
    margin-bottom: 120px;
    z-index: 1;

    @include breakpoint (medium down) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 60px;
    }

    &:after {
        @include breakpoint (medium down) {
            content: '';
            background-color: $primary-color;
            position: absolute;
            height: 90%;
            transform: translateX(-50%);
            left: 50%;
            z-index: -1;
            bottom: 0;
        }

        @include breakpoint (small only) {
            @include xy-cell(12, $gutters: 0);
        }

        @include breakpoint (medium only) {
            @include xy-cell(10, $gutters: 0);
        }
    }

    img {
        width: 100%;
    }

    .text {
        @include breakpoint (small) {
            padding: 20px;
        }

        @include breakpoint (medium) {
            padding: 20px 40px 20px 40px;
        }

        @include breakpoint (medium only) {
            text-align: center;
        }

        @include breakpoint (large) {
            padding: 40px 125px 40px 40px;
        }
    }

    > div {
        &:first-child {
            display: flex;
            align-items: center;

            @include breakpoint (medium down) {
                order: 2;
            }
        }

        &:last-child {
            @include breakpoint (medium down) {
                order: 1;
            }
        }
    }

    h2 {
        margin-bottom: 20px;

        @include breakpoint (medium only) {
            font-size: rem-calc(28);
        }

        @include breakpoint (small only) {
            margin-bottom: 10px;
        }
    }
}
