.widget-2-col-img-txt-highlands-wrapper {
    @include breakpoint (medium) {
        margin-bottom: 60px;
    }

    @include breakpoint (large) {
        margin-bottom: 120px;
    }

    .big-text {
        @include breakpoint (small only) {
            display: none;
        }

        @include breakpoint (medium) {
            margin-bottom: 30px;
            font-size: rem-calc(90);
        }

        @include breakpoint (large) {
            margin-bottom: 40px;
        }
    }
}

.highlands-side-widget {
    position: relative;
    z-index: 1;

    @include breakpoint (small only) {
        padding: 0 20px 20px 20px;
        margin-bottom: 30px;
    }

    @include breakpoint (medium) {
        padding-top: 60px
    }

    &:after {
        @include breakpoint (small only) {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 80%;
            background-color: $primary-color;
            z-index: -1;
        }
    }
    

    img {
        width: 100%;
    }

    p {
        @include breakpoint (small only) {
            margin: 0;
        }

        @include breakpoint (medium) {
            padding-top: 20px;
        }
    }

    h1 {
        margin-top: 20px;

        @include breakpoint (medium) {
            display: none;
        }
    }
}