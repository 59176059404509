.widget-recently-viewed {
    padding-right: 0;
    overflow: hidden;
    
    .recently-viewed-wrapper {
        position: relative;
        z-index: 1;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            height: 50%;
            background-color: $primary-color;

            @include breakpoint(small only) {
                @include xy-cell(12, $gutters: 0);
            }

            @include breakpoint(medium only) {
                @include xy-cell(8, $gutters: 0);
            }

            @include breakpoint(large) {
                @include xy-cell(6, $gutters: 0);
            }
        }
    }

    h3 {
        @include breakpoint(small only) {
            margin-left: 40px;
            padding-top: 20px;
        }

        @include breakpoint(medium only) {
            margin-left: 60px;
        }

        @include breakpoint(medium) {
            padding-top: 40px;
        }

        @include breakpoint(large) {
            margin-left: 120px;
        }
    }

    .control-wrapper {
        position: relative;
        
        @include breakpoint(large) {
            margin-right: 7%;
        }
    }

    .widget-ctrl {
        position: absolute;
        top: 40px;
        right: 0;

        @include breakpoint(medium down) {
            display: none;
        }
    }

    .recently-viewed-slider {
        overflow: hidden;

        @include breakpoint(small only) {
            margin-left: 40px;
        }

        @include breakpoint(medium only) {
            margin-left: 60px;
        }

        @include breakpoint(large) {
            margin-left: 120px;
        }
    
        .slider {
            overflow: hidden;

            &:not(.owl-loaded) {
                display: flex;
                overflow: hidden;
            }
    
            &.owl-loaded {
                height: auto;
                overflow: visible;
            }
        }
    
        .owl-item {
            float: left;
        }
    
        .owl-dots,
        .owl-nav {
            display: none;
        }
    }
}