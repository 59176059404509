.widget {
    position: relative;
    z-index: 1;

    .text {
        background-color: $primary-color;
    }

    h1 {
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 10px;
    }

    .button {
        &.editorial {
            position: relative;
            border-bottom: 0;
            padding-bottom: 7px;

            @include breakpoint(medium) {
                overflow: hidden;
            }

            @include breakpoint(small only) {
                overflow: inherit;
            }

            &:after {
                content: '';
                display: inline-block;
                position: absolute;
                top: calc(100% - 2px);
                left: 0;
                width: 100%;
                height: 2px;
                background-color: #DBB19F;
                transform-origin: left;
                transition: transform 0.35s ease;
            }

            &:after {
                transform: translate3d(0, 0, 0);

                @include breakpoint(small only) {
                    width: 46px;
                    height: 46px;
                    border: 2px solid #DBB19F;
                    background-color: transparent;
                    border-radius: 23px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                }
            }

            &:hover {
                &:after {
                    transform: translate3d(100%, 0, 0);

                    @include breakpoint(small only) {
                        transform: translate3d(-50%, -50%, 0);
                    }
                }
            }
        }
    }
}
