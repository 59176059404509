.widget-2-col-img-img-txt {
    position: relative;
    z-index: 1;

    &:after {
        content: '';
        position: absolute;
        left: 100px;
        right: 100px;
        height: 80%;
        bottom: 0;
        background-color: $primary-color;
        z-index: -1;
    }

    @include breakpoint (small only) {
        margin-bottom: 60px;
    }

    @include breakpoint (medium) {
        margin-bottom: 120px;
    }

    img {
        width: 100%;
    }

    .text {
        padding: 20px 0;
        text-align: center;
    }

    h2 {
        @include breakpoint (small only) {
            font-size: rem-calc(28);
        }
    }

    @include breakpoint (medium down) {
        img {
            display: block;
            width: 90%;
            margin: 0 auto;
        }

        &:after {
            left: 0;
            right: 0;
        } 
    }
}
