.widget-1-col-big-img {
    margin-bottom: 130px;

    @include breakpoint (medium down) {
        margin-bottom: 90px;
    }

    &:after {
        content: '';
        @include xy-cell(0, $gutters: 0);
        background-color: $primary-color;
        position: absolute;
        height: 80%;
        transform: translateX(-50%);
        left: 50%;
        z-index: -1;
        bottom: 0;
    }

    img {
        width: 100%;
    }

    .text {
        padding: 20px;
        text-align: center;
    }

    h2 {
        @include breakpoint (small only) {
            font-size: rem-calc(28);
        }
    }

    @include breakpoint (medium down) {
        img {
            display: block;
            width: 90%;
            margin: 0 auto;
        }

        &:after { 
            @include xy-cell(12, $gutters: 0);
        }
    }
}
