.five-product-slot,
.four-product-slot {
    @include breakpoint (medium) {
        margin-bottom: 60px
    }

    @include breakpoint (large) {
        margin-bottom: 120px
    }
}

@import '../pdp/vars';

.wishlist-list-size {
    ul {
        @include xy-grid;
        @include xy-gutters($negative: true);
        margin-top: 25px;
        margin-bottom: 16px;
        padding-left: 10px;
        padding-right: 10px;

        @include breakpoint(medium down) {
            max-width: 375px;
            margin-top: 50px;
            margin-left: auto;
            margin-right: auto;
        }

        @include breakpoint($breakpoint-extra down) {
            @include xy-gutters(10px, $negative: true);
            margin-top: 10px;
            padding-left: 0;
            padding-right: 0;
        }

        > li {
            @include xy-cell(1 of 8, true, 10px);
            font-size: 16px;
            height: 40px;
            margin-bottom: 10px;
            transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;

            @include breakpoint(medium down) {
                @include xy-cell(1 of 6, true, 10px);
            }

            @include breakpoint($breakpoint-extra down) {
                @include xy-cell(1 of 5, true, 10px);
            }

            &:not([disabled]) {
                font-weight: 500;
                color: black;
                border: 1px solid #ccc;
                cursor: pointer;

                @include breakpoint($breakpoint-extra down) {
                    border-color: #000;
                }
            }

            &.selected {
                color: white;
                background-color: black;
                border-color: transparent;
            }
        }
    }
}