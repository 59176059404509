.widget-1-col-fullscreen {
    position: relative;
    background-color: #f7f7f7;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 350px;

    @include breakpoint(large) {
        height: calc(100vh - 230px);
        margin-bottom: 150px;
        background-position: center;
    }

    @include breakpoint(xlarge) {
        height: calc(100vh - 230px);
        margin-bottom: 220px;
        background-position: center;
    }

    @include breakpoint(xxlarge) {
        height: calc(100vh - 230px);
    }

    @include breakpoint(medium down) {
        display: block;
        padding-top: 100%;
        background-size: contain;
        height: auto;
        min-height: auto;
    }

    @include breakpoint(medium only) {
        padding-top: 469px;
        background-size: cover;
        background-position: center;
    }

    @include breakpoint(medium down) {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 90px;
    }

    img {
        width: 100%;
    }

    p, h1 {
        margin: 0;
    }

    .text {
        background-color: $primary-color;

        @include breakpoint(medium down) {
            padding: 20px;
            text-align: center;
        }

        @include breakpoint(large) {
            padding: 40px;
            position: absolute;
            // bottom: 0;
            bottom: -75px;
            left: 0;
            width: 80%;
        }

        @include breakpoint(medium down) {
            width: 100%;
            align-self: flex-end;
            padding-bottom: 40px;
        }
    }

    .cta-render {
        @include breakpoint(large) {
            padding: 40px;
            position: absolute;
            // bottom: 0;
            bottom: -75px;
            right: 0;
            width: 20%;
            background-color: white;
        }

        @include breakpoint(medium down) {
            position: absolute;
            bottom: -18px;
            width: 100%;
        }
    }

    .button.editorial {
        @include breakpoint (medium down) {
            text-indent: -10000px;
            border: none;
            width: 38px;
            height: 38px;
            background-color: $secondary-color;
            border-radius: 19px;
            position: relative;
            margin: 0;
            padding: 0;
        }

        &:before {
            @include breakpoint (medium down) {
                border-style: solid;
                border-width: 2px 2px 0 0;
                content: '';
                display: block;
                height: 9px;
                width: 9px;
                position: absolute;
                vertical-align: top;
                margin-left: -2px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%) rotate(45deg);
            }
        }

        &:after {
            @include breakpoint (medium down) {
                content: '';
                width: 46px;
                height: 46px;
                border: 2px solid $secondary-color;
                background-color: transparent;
                border-radius: 23px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: scale3d(1, 1, 1) translateX(-50%) translateY(-50%);
            }
        }

        &:hover {
            &:after {
                @include breakpoint (medium down) {
                    transform: scale3d(1, 1, 1) translateX(-50%) translateY(-50%);
                }
            }
        }
    }
}
