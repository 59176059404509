.widget-2-col-img-img {

    margin-bottom: 130px;

    img {
        width: 100%;
    }

    div {
        &.align-bottom {
            display: flex;
            align-items: flex-end;
        }

        &:first-child {
            @include breakpoint (small only) {
                margin-bottom: 60px;
            }
        }
    }

    blockquote {
        @include breakpoint (large down) {
            margin-bottom: 40px;
        }

        @include breakpoint (small only) {
            margin-top: -10px;
            margin-bottom: 0px;
        }

        &:before {
            @include breakpoint (small only) {
                top: -60px;
                left: -10px;
            }
        }
    }
}
