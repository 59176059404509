.widget-1-col-img-txt-center {
    position: relative;
    z-index: 1;
    margin-bottom: 130px;

    @include breakpoint (medium down) {
        margin-bottom: 60px;
    }

    &:after {
        content: '';
        @include xy-cell(8, $gutters: 0);
        background-color: $primary-color;
        position: absolute;
        height: 80%;
        transform: translateX(-50%);
        left: 50%;
        z-index: -1;
        bottom: 0;
        margin-bottom: 0px;

        @include breakpoint (medium down) {
            height: 90%;
        }
    }

    img {
        width: 100%;
    }

    .text {
        text-align: center;
        padding: 20px 0;
    }

    h2 {
        @include breakpoint (medium only) {
            font-size: rem-calc(28);
        }
    }

    @include breakpoint (medium down) {
        img {
            display: block;
            width: 90%;
            margin: 0 auto;
        }

        &:after { 
            @include xy-cell(12, $gutters: 0);
        }
    }
}